
.card-shadow-on:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.App {
    background: url("https://raw.githubusercontent.com/tomkotara/foldertreetk/main/futu-drg-splash-ikm.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    overflow-x: hidden;
}

.bg-drg {
    background-color: #07ADBE;
}

.main-color {
    color: #07ADBE;
}

.badge {
    white-space: normal;
}

.highlightSpan{
    background: #ffc107;
}

/* PrescriptionShow page */

.name-box h1 {
    font-size: 1.75rem;
}

.logo-fix-size {
    width: auto;
    height: 4rem;
}

.profession-title {
    margin: 0 0;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    padding: 0;
}

.copyright-box {
    margin-top: 65vh;
    font-size: .75rem;
    /*background-color: rgba(255, 255, 255, 0.85);*/
    background-color: white;
}

/* ------- */