.editor-wrapper {
    min-height: 500px;
    border: 2px solid #ccc;
    border-radius: 12px;
    box-shadow: 4px 4px #dbdbdb;
}

.editor-wrapper-smaller {
    min-height: 250px;
    border: 2px solid #ccc;
    border-radius: 12px;
    box-shadow: 4px 4px #dbdbdb;
}

.editor-wrapper-smallest {
    min-height: 100px;
    border: 2px solid #ccc;
    border-radius: 12px;
    box-shadow: 4px 4px #dbdbdb;
}

.color-picker-popup{
    height: 200px;
    width: 400px;
}

.color-picker-btn{
    background: #ceeefc;
    width: 30px;
    height: 30px;
    border-radius: 7px;
}

.editor-toolbar {
    border: 0 solid #ccc;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-width: 2px;
}

.editor-editor {
    padding-left: 10px;
    padding-right: 10px;
}


/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}